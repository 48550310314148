import * as React from "react"
import Content from "../components/Content/Content"
import Layout from "../components/Layout/Layout"
import LinkStyle from "../components/LinkStyle/LinkStyle"
import Seo from "../components/SEO/SEO"
import TitleStyle from "../components/TitleStyle/TitleStyle"

const ContactPage = () => (
  <Layout>
    <Seo title="Kontakt" />
    <Content>
        <TitleStyle>Obsługa Klienta</TitleStyle>
        <p>Dziękujemy za zainteresowanie nasza ofertą i odwiedzenie strony MERCE Polska.</p>
        
        <p>Jeśli masz pytanie, zadzwoń pod numer <LinkStyle href='tel:+48412306780' >41 230 67 80</LinkStyle>.</p>
        
        <p>Jesteśmy dostępni od poniedziałku do piątku w godzinach 9:00 – 17:00.</p>
        
        <p>Życzymy udanych zakupów!</p>       
    </Content>
  </Layout>
)

export default ContactPage
